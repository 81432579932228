import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'
import TelegramIcon from '@material-ui/icons/Telegram'
import FacebookIcon from '@material-ui/icons/Facebook'
import { Link } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import { FaDiscord } from 'react-icons/fa'

import {} from 'react-feather'
const useStyles = makeStyles((theme) => ({
  socialicons: {
    // color: "#0b0b6dc9",
    // background: "#fafafa",
    padding: '10px',
    // fontSize: "24px",
    // width: "30px",
    // height: "30px",
    textAlign: 'center',
    textDecoration: 'none',
    margin: '5px 2px',
    // borderRadius: "50%",
    // position:"fixed",
    '&:hover': {
      // background: "#5FD58C",
      // transform: "rotateY(360deg)",
      transition: '.5s',
      // color: "green",
      // fontSize:"50px",
      zIndex: '2',
    },
  },

  footerSection: {
    // backgroundImage:
    //   "linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )",
    background: 'linear-gradient(to right, #1ca23e, #222222a1, #254688)',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
    '& h6': {
      fontSize: '16px',
      lineHeight: '22px',
      color: ' #fff',
      fontWeight: '400',
      fontFamily: "'Nunito', sans-serif",

      '& @media(max-width:420px)': {
        fontSize: '10px',
        lineHeight: '18px',
      },

      '& @media(max-width:768px)': {
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  borderBottmo: {
    overflow: 'hidden',
    background: '#000',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: '8px',
  },
  footerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  },
}))

export default function Liquidity() {
  const classes = useStyles()
  return (
    <>
      {/* <Box className={classes.borderBottmo}>
        <img
          src="images/borderfooter.png"
          className="img-responsive"
          alt=" "
          style={{ width: "100%", display: "block" }}
        />
      </Box> */}
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3" className="">
                  ABOUT
                </Typography>
                <Typography variant="h6" className="">
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo commodo. Proin sodales
                  pulvinar tempor. Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus.
                </Typography>
                <Typography variant="body2" className=""></Typography>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">RECENT WORKS</Typography>
                <List className="recentWork">
                  <ListItem>
                    <img
                      src="images/img1.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img2.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img3.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img4.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img5.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                  <ListItem>
                    <img
                      src="images/img6.png"
                      className="img-responsive"
                      alt=" "
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">TAG CLOUD</Typography>
                <Box className="tagcloud">
                  <Link>Analyze</Link>
                  <Link>audio</Link>
                  <Link>blog</Link>
                  <Link>Business</Link>
                  <Link>Creative</Link>
                  <Link>design</Link>
                  <Link>Experiment</Link>
                  <Link>Expertize</Link>
                  <Link>Business</Link>
                  <Link>Creative</Link>
                  <Link>design</Link>
                  <Link>Experiment</Link>
                  <Link>Expertize</Link>
                </Box>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">RECENT NEWS</Typography>
                <Box className="recent-posts-widget">
                  <List>
                    <ListItem>
                      <Box className="entry-title">
                        <Typography variant="h4">
                          INTEGER AT DIAM GRAVIDA FRINGILLA NIBH PRETI PURUS
                        </Typography>
                      </Box>
                      <Box className="entry-meta">
                        <Typography variant="body1">
                          {" "}
                          <AccessTimeIcon className={classes.largeIcon} /> Jun
                          18{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <ChatBubbleOutlineIcon
                            className={classes.largeIcon}
                          />{" "}
                          1
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box className="entry-title">
                        <Typography variant="h4">
                          DONEC QUIS EX VEL TINCIDUNT
                        </Typography>
                      </Box>
                      <Box className="entry-meta">
                        <Typography variant="body1">
                          {" "}
                          <AccessTimeIcon className={classes.largeIcon} /> Jun
                          18{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <ChatBubbleOutlineIcon
                            className={classes.largeIcon}
                          />{" "}
                          1
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box className="entry-title">
                        <Typography variant="h4">
                          PRAESENT IACULIS TORTOR VIVERRA
                        </Typography>
                      </Box>
                      <Box className="entry-meta">
                        <Typography variant="body1">
                          {" "}
                          <AccessTimeIcon className={classes.largeIcon} /> Jun
                          18{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <ChatBubbleOutlineIcon
                            className={classes.largeIcon}
                          />{" "}
                          1
                        </Typography>
                      </Box>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Box textAlign="center" className="FooterMenu">
                {/* <List>
                  <ListItem>
                    <Link to="/terms-conditions">
                      <Typography variant="h6">TERMS OF USE</Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/grant-terms" color="inherit">
                      <Typography variant="h6" className="">
                        Grant Program Terms
                      </Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/faq" color="inherit">
                      <Typography variant="h6" className="">
                        FAQ
                      </Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/disclaimer" color="inherit">
                      <Typography variant="h6" className="">
                        Disclaimer
                      </Typography>
                    </Link>
                  </ListItem>
                </List> */}
                <Container>
                  <Box className={classes.footerContent} style={{}}>
                    {/* <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  container
                >
                  <Grid item lg={2} sm={6} md={3} xs={12}> */}
                    <Link pd={2} pt={2} to="/grant-terms" color="inherit">
                      <Typography variant="h6" className="">
                        GRANT PROGRAM TERMS
                      </Typography>
                    </Link>
                    {/* </Grid>
                  <Grid item lg={2} sm={6} md={3} xs={12}> */}
                    <Link pd={2} pt={2} to="/terms-conditions">
                      <Typography variant="h6">TERMS OF USE</Typography>
                    </Link>
                    {/* </Grid>
                  <Grid item lg={2} sm={6} md={3} xs={12}> */}
                    <Link pd={2} pt={2} to="/disclaimer" color="inherit">
                      <Typography variant="h6" className="">
                        DISCLAIMER
                      </Typography>
                    </Link>
                    {/* </Grid>
                  <Grid item lg={2} sm={6} md={3} xs={12}> */}
                    <Link pd={2} pt={2} to="/faq" color="inherit">
                      <Typography variant="h6" className="">
                        FAQ
                      </Typography>
                    </Link>
                    {/* </Grid>
                </Grid> */}
                  </Box>
                </Container>
              </Box>
              <hr />
              <Box className="FooterMenu">
                <List>
                  <ListItem>
                    <Link href="https://discord.gg/Ah99MGpWgW" color="inherit">
                      <FaDiscord className={classes.socialicons} />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="http://@Splendid_Sloths" color="inherit">
                      <TwitterIcon className={classes.socialicons} />
                    </Link>
                  </ListItem>
                  {/*<ListItem>
                    <Link to="#" color="inherit">
                      <TelegramIcon className={classes.socialicons} />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="#" color="inherit">
                      <FacebookIcon className={classes.socialicons} />
                    </Link>
                  </ListItem>*/}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

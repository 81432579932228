import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Box,
  Container,
  DialogTitle,
} from "@material-ui/core";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "src/context/User";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Web3 from "web3";
import { getContract } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import { token } from "src/constants";
import abi from "src/abis/Abi2.json";
import { ethers } from "ethers";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "100px",
    position: "absolute",
    backgroundColor: "transparent",
    paddingRight: "59px",
    paddingLeft: "115px",
    "@media (max-width: 1280px)": {
      paddingLeft: "75px",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "55px",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
  },

  menuButton: {
    size: "18px",
    marginLeft: "38px",
    color: "#fff",
    fontWeight: 400,
    textTransform: "uppercase",
    letterSpacing: "1px",
    "@media (max-width: 900px)": {
      fontSize: "16px",
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "60px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#5FD58C",
    width: "260px",
  },
  drawericon: {
    color: "#fff",
    position: "absolute",
    top: "3px",
    right: "20px",
    fontSize: "25px",
  },
  logoImg: {
    width: "60px",
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },

  typographydia: {
    overflow: "hidden",
    flexWrap: "nowrap",
    width: "50px",
    color: "white",
  },
  diabox: {
    height: "5px",
    backgroundColor: "white",
    borderRight: "2px solid grey",
  },
  diagrid: {},
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  typographyETH: {
    color: "white",
  },
}));

function Mintpopup({ open2, handleClose2 }) {
  const {
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    typographydia,
    diabox,
    typographyETH,
  } = useStyles();
  const [numberofnft, setNumberofnft] = React.useState("");
  const [alertmsg, setalertMsg] = React.useState("");
  const [alertseverity, setalertSeverity] = React.useState("");
  const [openalert, setOpenalert] = React.useState(false);
  const [nftprice, setNftprice] = React.useState();
  const [isSubmit, setisSubmit] = React.useState(false);
  const { account, library } = useWeb3React();
  const [openloader, setOpenloader] = React.useState(false);
  const user = React.useContext(UserContext);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const handleClickOpen2 = async () => {
    if (typeof account === "undefined") {
      user.connectWallet();
    } else {
      //     const web3=new Web3(Web3.givenProvider);
      //   const contract=new web3.eth.Contract(abi,address);
      const contract = getContract(token, abi, library, account);
      console.log(contract);
      const tx = await contract.getNFTPrice();
      console.log(tx);
      // const etherValue = Web3.utils.fromWei(result ,'ether');
      //format value from bignumber to user ether ,passEther ether->big
      const etherValue = ethers.utils.formatEther(tx);
      console.log("hello");
      console.log(etherValue);
      setNftprice(etherValue);
    }
  };
  useEffect(() => {
    handleClickOpen2();
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const handleToggle = () => {
    setOpenloader(true);
  };
  const handleClickalert = () => {
    setOpenalert(true);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setNumberofnft(e.target.value);
  };
  const handleCloseloader = () => {
    setOpenloader(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClosealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenalert(false);
    // setalertMsg({[alertmsg.msg]:"",[alertmsg.severity]:''})
    setalertMsg("");
    setalertSeverity("");
  };
  const mintNFT = async () => {
    // if(typeof account!=='undefined')
    // {
    //   user.connectWallet();
    // }else{
    setisSubmit(true);
    if (numberofnft && numberofnft !== "") {
      handleToggle();
      // const contract = getContract(token, abi, library, account);
      const web3 = (window.web3 = new Web3(window.ethereum));
      const contract = new web3.eth.Contract(abi, token);

      // const signer=contract.connect(account);

      try {
        console.log(contract);
        const tx = await contract.methods.mintNFT(numberofnft).send({
          value: ethers.utils
            .parseEther((numberofnft * nftprice).toString())
            .toString(),
          gasLimit: 3000000,
          from: account,
        });
        // await tx.wait();
        console.log(tx);
        if (tx.status !== false) {
          handleClickalert();
          setalertMsg("your transaction is successful!");
          setalertSeverity("success");
          handleCloseloader();
          // handleClose2();
        } else {
          handleClickalert();
          setalertMsg("your Transaction is failed pls Try again");
          setalertSeverity("error");
          console.log(alertmsg);
          handleCloseloader();
        }
      } catch (e) {
        console.log(e);
        handleClickalert();
        setalertMsg("your Transaction is failed pls Try again");
        setalertSeverity("error");
        handleClose2();
        handleCloseloader();
      }
    }
    // }
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open2}
        onClose={handleClose2}
        style={{ margin: "0px" }}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{
            backgroundColor: "rgb(25 113 44 / 83%)",
            padding: "7px",
          }}
        >
          <Typography
            variant="h3"
            align="center"
            style={{ marginBottom: 16, color: "white" }}
          >
            Mint SSS
          </Typography>
          <Grid
            container
            align="left"
            direction="row"
            xs={12}
            style={{ flexWrap: "nowrap" }}
          >
            <Grid item container direction="column" style={{ width: "112px" }}>
              <Typography className={typographydia}>3000 NFTs</Typography>
              <Box style={{ width: "110px" }} className={diabox} />
              <Typography className={typographyETH}>0.1 ETH</Typography>
            </Grid>

            <Grid item container direction="column" style={{ width: "102px" }}>
              <Typography className={typographydia} style={{}}>
                4000 NFT's
              </Typography>

              <Box style={{ width: "100px" }} className={diabox} />
              <Typography className={typographyETH}>0.3ETH</Typography>
            </Grid>
            <Grid item container direction="column" style={{ width: "92px" }}>
              <Typography className={typographydia}> 4000 NFTs</Typography>

              <Box style={{ width: "90px" }} className={diabox} />
              <Typography className={typographyETH}>0.5 ETH</Typography>
            </Grid>
            <Grid item container direction="column" style={{ width: "82px" }}>
              <Typography className={typographydia}> 4000 NFTs</Typography>

              <Box style={{ width: "80px" }} className={diabox} />
              <Typography className={typographyETH}>0.9 ETH</Typography>
            </Grid>
            <Grid item container direction="column" style={{ width: "72px" }}>
              <Typography className={typographydia}> 1000 NFTs</Typography>

              <Box style={{ width: "70px" }} className={diabox} />
              <Typography className={typographyETH}>1.7ETH</Typography>
            </Grid>

            <Grid item container direction="column" style={{ width: "62px" }}>
              <Typography className={typographydia}>381 NFT's</Typography>

              <Box style={{ width: "60px" }} className={diabox} />
              <Typography className={typographyETH}>3.0 ETH</Typography>
            </Grid>
            <Grid item container direction="column" style={{ width: "52px" }}>
              <Typography className={typographydia}>3 NFTs</Typography>

              <Box style={{ width: "55px" }} className={diabox} />
              <Typography className={typographyETH} style={{ width: "55px" }}>
                100ETH
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent
          style={{
            backgroundColor: "rgb(25 113 44 / 83%)",
            border: "px solid white",
            padding: 25,
          }}
        >
          <DialogContentText style={{ backgroundColor: "black" }}>
            <Typography
              variant="h8"
              style={{ color: "white", fontSize: "25px" }}
            ></Typography>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <Typography
                align="left"
                variant="h8"
                style={{
                  marginTop: "10px",
                  color: "white",
                  fontSize: "22px",
                }}
                xs={4}
              >
                NFT Price :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Typography
                align="left"
                variant="h8"
                style={{
                  marginTop: "10px",
                  color: "white",
                  fontSize: "22px",
                }}
              >
                {nftprice}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <Typography
                align="left"
                variant="h8"
                style={{
                  marginTop: "10px",
                  color: "white",
                  fontSize: "22px",
                }}
              >
                Number of NFT :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <TextField
                size="small"
                autoFocus
                id="name"
                variant="outlined"
                type="number"
                style={{
                  minHeight: "39px",
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "5px",
                }}
                onChange={handleChange}
                error={isSubmit && numberofnft === ""}
              />
              {isSubmit && numberofnft === "" && (
                <Typography variant="body2" style={{ color: "red" }}>
                  Enter value
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <Typography
                align="left"
                variant="h8"
                style={{
                  marginTop: "10px",
                  color: "white",
                  fontSize: "25px",
                }}
                xs={4}
              >
                Total Price:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Typography
                align="left"
                variant="h8"
                style={{
                  marginTop: "10px",
                  color: "white",
                  fontSize: "25px",
                }}
              >
                {numberofnft * nftprice}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className="btnfilter"
          style={{ backgroundColor: "rgb(25 113 44 / 83%)" }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose2}
            style={{
              background: "#bf4646",
              color: "white",
              marginTop: "10px",
              marginBottom: 15,
            }}
          >
            Close
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={mintNFT}
            style={{
              background: "#1f263b",
              color: "white",
              marginTop: "10px",
              marginLeft: "25px",
              marginRight: "13px",
              marginBottom: 15,
            }}
          >
            {typeof account !== "undefined" ? "Mint" : "Connect"}
          </Button>

          <Snackbar
            open={openalert}
            autoHideDuration={3000}
            onClose={handleClosealert}
          >
            <Alert onClose={handleClosealert} severity={alertseverity}>
              {alertmsg}
            </Alert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Mintpopup;
